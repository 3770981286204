import * as yup from 'yup';

const contactSchema = yup.object().shape({
  companyRFCName: yup
    .string()
    .min(3, 'Por favor pon nombre de tu empresa.')
    .required('Por favor pon el nombre de tu empresa.'),
  address: yup
    .string()
    .min(
      3,
      'Por favor pon la dirreción de tu empresa para que enviemos tu terminal.'
    )
    .required(
      'Por favor pon la dirreción de tu empresa para que enviemos tu terminal.'
    ),
  postal_code: yup
    .string()
    .required(
      'Por favor pon el código postal de tu empresa para que enviemos tu terminal.'
    )
    .matches(/^[0-9]+$/, 'El código postal solo puede contener dígitos.')
    .min(5, 'El código postal debe ser de 5 dígitos.')
    .max(5, 'El código postal debe ser de 5 dígitos.'),
  city: yup
    .string()
    .min(
      3,
      'Por favor pon la ciudad donde se ubica tu empresa para que enviemos tu terminal.'
    )
    .required(
      'Por favor pon la ciudad donde se ubica tu empresa para que enviemos tu terminal.'
    ),
  mobileTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  staticTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  inalambricoTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  personFirstname: yup.string().required('Por favor pon tu nombre.'),
  personLastname: yup.string().required('Por favor pon tus apellidos.'),
  email: yup
    .string()
    .email('Este correo electrónico es invalido.')
    .required(
      'Por favor pon tu correo electrónico para que te mandamos la oferta y actualicaciones de tu selección.'
    ),
  phone: yup
    .string()
    .required(
      'Por favor pon el número telefónoico para seguimento y consultas sobre nuestros terminales.'
    )
    .matches(/^[0-9]+$/, 'El número telefónoico solo puede contener dígitos.')
    .min(10, 'El número debe ser de 10 dígitos.')
    .max(10, 'El número debe ser de 10 dígitos.'),

  password: yup
    .string()
    .required()
    .min(5, 'La contraseña tiene que ser mínimo 5 carácteres.'),
  passwordConfirm: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Las contraseñas no son iguales.'),

  contractStart: yup
    .date()
    .required()
    // Check that date is newer than day before yesterday, 1 day extra to have server date settings margin.
    .min(
      new Date(new Date().getTime() - 86400000 * 2),
      'Por favor elige una fecha en el futuro.'
    ),
});

export default contactSchema;
